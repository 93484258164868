<template>
    <section ref="section" class="table-responsive scroll-mt-7">
        <table :class="['table table-striped table-hover', fixed ? `table-fixed-${columns}` : '']">
            <thead>
                <tr>
                    <td :colspan="columns">
                        <nav v-if="pages > 1">
                            <ul class="pagination pagination-sm justify-content-center mb-0">
                                <li v-for="p in pagesRange" :class="['page-item', p.page === page ? 'active' : '', p.enabled ? '' : 'disabled']">
                                    <a v-if="p.enabled" class="page-link" href="#" @click.prevent="page = p.page">
                                        {{ p.text }}
                                    </a>
                                    <span v-else class="page-link">
                                        {{ p.text }}
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
                <tr v-if="count">
                    <td :colspan="columns">
                        <T>table.count</T><T>quotation.colon</T>
                        <strong>{{ data.length }}</strong>
                    </td>
                </tr>
                <tr>
                    <slot name="header"></slot>
                </tr>
            </thead>
            <tbody>
                <template v-if="data.length">
                    <tr v-for="el in dataPage" :key="el[rowKey]" :class="{ marked: marked ? marked(el) : false }">
                        <slot name="row" :el="el"></slot>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td :colspan="columns" class="text-center">
                            <slot name="empty">
                                <Icon v="search" />
                                <T>table.empty</T>
                            </slot>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tfoot>
                <tr>
                    <td :colspan="columns + 1">
                        <nav v-if="pages > 1">
                            <ul class="pagination pagination-sm justify-content-center">
                                <li v-for="p in pagesRange" :class="['page-item', p.page === page ? 'active' : '', p.enabled ? '' : 'disabled']">
                                    <a v-if="p.enabled" class="page-link" href="#" @click.prevent="page = p.page">
                                        {{ p.text }}
                                    </a>
                                    <span v-else class="page-link">
                                        {{ p.text }}
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
            </tfoot>
        </table>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

type VPage = {
    page: number;
    text: string | number;
    enabled: boolean;
} | {
    page?: never;
    text: string;
    enabled: false;
};

export default defineComponent({
    props: {
        data: { required: true, type: Array },
        columns: { required: true, type: Number },
        perPage: { default: 30, type: Number },
        rowKey: { default: 'id', type: String },
        marked: { type: Function },
        fixed: { type: Boolean },
        count: { type: Boolean },
    },
    setup() {
        return {
            section: useTemplateRef<HTMLElement>('section'),
            page: ref(0),
        };
    },
    computed: {
        dataPage(): unknown {
            return this.data.slice(this.page * this.perPage, (this.page + 1) * this.perPage);
        },
        pages(): number {
            return Math.ceil(this.data.length / this.perPage);
        },
        pagesRange(): VPage[] {
            const vPages = [];
            vPages.push({ page: 0, text: '«', enabled: this.page > 0 });
            vPages.push({ page: this.page - 1, text: '‹', enabled: this.page > 0 });
            for (let i = 0; i < this.pages; i++) {
                if (i <= 4 || this.page - 3 <= i && i <= this.page + 3 || i >= this.pages - 3) {
                    vPages.push({ page: i, text: i + 1, enabled: true });
                } else if (vPages[vPages.length - 1].text !== '…') {
                    vPages.push({ text: '…', enabled: false } as const);
                }
            }
            vPages.push({ page: this.page + 1, text: '›', enabled: this.page < this.pages - 1 });
            vPages.push({ page: this.pages - 1, text: '»', enabled: this.page < this.pages - 1 });
            return vPages;
        },
    },
    methods: {
        reset() {
            this.page = 0;
        },
        focus() {
            this.section?.scrollIntoView();
        },
    },
});
</script>

<style lang="scss">
    @import "assets/variables";

    .marked {
        border-inline-start: 3px solid $primary;
    }
</style>
